var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landingPage"},[_c('nav',[_c('div',{staticClass:"inner flexB"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo3.png"),"alt":"로고"}})])],1),_c('router-link',{staticClass:"loginBtn",attrs:{"to":"/account/login"}},[_vm._v("로그인")])],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"flexB"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"txt"},[_c('h1',[_vm._v("건강한 농작물의 시작")]),_c('h1',[_vm._v("Green Lens")]),_c('p',[_vm._v("농작물을 AI로 실시간 진단해보세요!")])]),_c('div',{staticClass:"flex goStore"},[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=kr.gapnote.greenlens&hl=ko-KR","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/GooglePlay.png"),"alt":"GooglePlayStore Download"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"description first"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"image animated fadeInLeft delay-200"},[_c('img',{attrs:{"src":require("@/assets/images/mockup.png"),"alt":"mockup"}})]),_c('div',{staticClass:"text animated fadeInRight delay-200"},[_c('div',{staticClass:"txt"},[_c('p',[_vm._v("실시간 판별")]),_c('h2',[_vm._v("병해충과 질병을 "),_c('br'),_vm._v("실시간으로 진단")]),_c('span',[_vm._v("농장에서 식물을 촬영하고 진단 결과를 바로 확인 가능하며 미리 촬영한 사진은 업로드 기능을 통해 진단 결과를 확인 할 수 있습니다.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"description"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text animated fadeInLeft delay-200"},[_c('div',{staticClass:"txt"},[_c('p',[_vm._v("병해충 전문지식 제공")]),_c('h2',[_vm._v("정확하고 전문적인 "),_c('br'),_vm._v("정보를 제공")]),_c('span',[_vm._v("농업진흥청에서 제공하는 각종 병해충 정보를 앱 안에서 편리하게 조회할 수 있습니다.")])])]),_c('div',{staticClass:"image animated fadeInRight delay-200"},[_c('img',{attrs:{"src":require("@/assets/images/mockup2.png"),"alt":"mockup"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"description last"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"image one animated fadeInLeft delay-200"},[_c('img',{attrs:{"src":require("@/assets/images/mockup3.png"),"alt":"mockup"}})]),_c('div',{staticClass:"text animated fadeInRight delay-200"},[_c('div',{staticClass:"txt"},[_c('p',[_vm._v("커뮤니티")]),_c('h2',[_vm._v("회원들 간 정보 공유와 "),_c('br'),_vm._v("자유로운 소통")]),_c('span',[_vm._v("농업인이 필요한 정보를 직접 공유하고 소통해보세요. 나의 게시한 글과 댓글은 서로에게 힘이 됩니다.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"intro"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(" 농업에 혁신적인 기술을 제공하는 "),_c('br'),_vm._v("농작물 병해충 진단 AI "),_c('br'),_vm._v("그린렌즈입니다. ")])]),_c('p',{staticClass:"square"}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"left animated fadeInUpShort delay-200"},[_c('div',{staticClass:"txt"},[_c('h3',[_vm._v("언제 어디서나 쉽고 편하게")]),_c('p',[_vm._v("앱안에서 촬영과 분석을 한번에!")]),_c('p',[_vm._v("쉽고 빠른 진단 서비스")])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/bg.jpg"),"alt":"이미지"}})])]),_c('div',{staticClass:"right animated fadeInUpShort delay-600"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/bg2.jpg"),"alt":"이미지"}})]),_c('div',{staticClass:"txt"},[_c('h3',[_vm._v("정확하고 믿을 수 있는")]),_c('p',[_vm._v("지속적인 데이터 학습을 통해 지금 이 순간에도")]),_c('p',[_vm._v("그린렌즈는 똑똑해 지고 있습니다.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"inner"},[_c('p',{staticClass:"fwm"},[_vm._v("주식회사 그린데이터랩")]),_c('ul',{staticClass:"info"},[_c('li',[_vm._v("대표 : 이미경")]),_c('li',[_vm._v("주소 : 서울시 송파구 법원로 8길 13, 802")]),_c('li',[_vm._v("대표 번호 : 02-2039-8887")]),_c('li',[_vm._v("사업자 등록번호 : 589-88-02120")]),_c('li',[_vm._v("이메일 : gdatalab.dv@gmail.com")])]),_c('p',{staticClass:"copyright"},[_vm._v(" Copyright © Green Lens. All Rights Reserved. ")])])])
}]

export { render, staticRenderFns }