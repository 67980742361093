<template>
  <div class="landingPage">
    <nav>
      <div class="inner flexB">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/images/logo3.png" alt="로고"
          /></router-link>
        </div>
        <router-link class="loginBtn" to="/account/login">로그인</router-link>
      </div>
    </nav>
    <section class="main">
      <div class="inner">
        <div class="flexB">
          <div class="content">
            <div class="txt">
              <h1>건강한 농작물의 시작</h1>
              <!-- <h1>농작물 병해충 진단 AI</h1> -->
              <h1>Green Lens</h1>
              <p>농작물을 AI로 실시간 진단해보세요!</p>
            </div>
            <div class="flex goStore">
              <a
                href="https://play.google.com/store/apps/details?id=kr.gapnote.greenlens&hl=ko-KR"
                target="_blank"
              >
                <img
                  src="@/assets/images/GooglePlay.png"
                  alt="GooglePlayStore Download"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="description first">
      <div class="inner">
        <div class="flex">
          <div class="image animated fadeInLeft delay-200">
            <img src="@/assets/images/mockup.png" alt="mockup" />
          </div>
          <div class="text animated fadeInRight delay-200">
            <div class="txt">
              <p>실시간 판별</p>
              <h2>병해충과 질병을 <br />실시간으로 진단</h2>
              <span
                >농장에서 식물을 촬영하고 진단 결과를 바로 확인 가능하며 미리
                촬영한 사진은 업로드 기능을 통해 진단 결과를 확인 할 수
                있습니다.</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="description">
      <div class="inner">
        <div class="flex">
          <div class="text animated fadeInLeft delay-200">
            <div class="txt">
              <p>병해충 전문지식 제공</p>
              <h2>정확하고 전문적인 <br />정보를 제공</h2>
              <span
                >농업진흥청에서 제공하는 각종 병해충 정보를 앱 안에서 편리하게
                조회할 수 있습니다.</span
              >
            </div>
          </div>
          <div class="image animated fadeInRight delay-200">
            <img src="@/assets/images/mockup2.png" alt="mockup" />
          </div>
        </div>
      </div>
    </section>
    <section class="description last">
      <div class="inner">
        <div class="flex">
          <div class="image one animated fadeInLeft delay-200">
            <img src="@/assets/images/mockup3.png" alt="mockup" />
          </div>
          <div class="text animated fadeInRight delay-200">
            <div class="txt">
              <p>커뮤니티</p>
              <h2>회원들 간 정보 공유와 <br />자유로운 소통</h2>
              <span
                >농업인이 필요한 정보를 직접 공유하고 소통해보세요. 나의 게시한
                글과 댓글은 서로에게 힘이 됩니다.</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="intro">
      <div class="inner">
        <div class="title">
          <h2>
            농업에 혁신적인 기술을 제공하는 <br />농작물 병해충 진단 AI
            <br />그린렌즈입니다.
          </h2>
        </div>
        <p class="square"></p>
        <div class="flex">
          <div class="left animated fadeInUpShort delay-200">
            <div class="txt">
              <h3>언제 어디서나 쉽고 편하게</h3>
              <p>앱안에서 촬영과 분석을 한번에!</p>
              <p>쉽고 빠른 진단 서비스</p>
            </div>
            <div class="img">
              <img src="@/assets/images/bg.jpg" alt="이미지" />
            </div>
          </div>
          <div class="right animated fadeInUpShort delay-600">
            <div class="img">
              <img src="@/assets/images/bg2.jpg" alt="이미지" />
            </div>
            <div class="txt">
              <h3>정확하고 믿을 수 있는</h3>
              <p>지속적인 데이터 학습을 통해 지금 이 순간에도</p>
              <p>그린렌즈는 똑똑해 지고 있습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="inner">
        <!-- <ul class="terms flex">
          <li><router-link to="/">개인정보 처리방침</router-link></li>
          <li><router-link to="/">이용약관</router-link></li>
        </ul> -->
        <p class="fwm">주식회사 그린데이터랩</p>
        <ul class="info">
          <li>대표 : 이미경</li>
          <li>주소 : 서울시 송파구 법원로 8길 13, 802</li>
          <li>대표 번호 : 02-2039-8887</li>
          <li>사업자 등록번호 : 589-88-02120</li>
          <li>이메일 : gdatalab.dv@gmail.com</li>
        </ul>
        <p class="copyright">
          Copyright &copy; Green Lens. All Rights Reserved.
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  data() {
    return {
      pwOn: false,
      accountId: "",
      password: "",
      error: false,
    };
  },
  mounted() {
    this.observeElements();
    this.observeMainSection();
  },
  methods: {
    observeElements() {
      const options = {
        root: null, // 뷰포트를 루트로 사용
        threshold: 0.2, // 타겟의 10%가 보여질 때 콜백 실행
      };
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 타겟 요소가 뷰포트에 진입
            // entry.target 자체가 .flex 클래스를 가진 요소를 가리키도록 변경
            const targetChildren = entry.target.querySelectorAll(
              // ".description .image, .description .text"
              ".description .image, .description .text, .intro .left, .intro .right"
            );
            targetChildren.forEach((child) => {
              child.classList.add("go");
            });
            observer.unobserve(entry.target); // 애니메이션이 적용된 후 관찰 중지
          }
        });
      }, options);
      // 관찰 대상을 '.description .flex'로 변경하여 부모 요소를 직접 타겟팅
      const elements = document.querySelectorAll(
        ".description .flex, .intro .flex"
      );
      elements.forEach((el) => {
        observer.observe(el);
      });
    },
    observeMainSection() {
      const nav = document.querySelector("nav");
      const options = {
        root: null, // 뷰포트를 루트로 사용
        threshold: 0, // 타겟의 0%가 보여질 때 콜백 실행
      };
      new IntersectionObserver((entries) => {
        // 'observer' 변수 선언 제거
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            nav.classList.add("on");
          } else {
            nav.classList.remove("on");
          }
        });
      }, options).observe(document.querySelector(".main"));
      // const observer = new IntersectionObserver((entries, observer) => {
      //   entries.forEach((entry) => {
      //     if (!entry.isIntersecting) {
      //       // main 섹션의 맨 아래가 뷰포트에서 벗어났을 때
      //       nav.classList.add("on");
      //     } else {
      //       // main 섹션의 맨 아래가 뷰포트 내에 있을 때
      //       nav.classList.remove("on");
      //     }
      //   });
      // }, options);

      // // main 섹션의 맨 아래를 타겟팅하기 위해, main 섹션의 맨 아래에 위치한 요소나 마커를 선택하거나 추가해야 합니다.
      // // 이 예제에서는 main 섹션 자체를 관찰 대상으로 설정합니다.
      // const mainSection = document.querySelector(".main");
      // observer.observe(mainSection);
    },
  },
};
</script>
